/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";

import Connect from "components/connect";
import StayUpdated from "components/stayUpdated";
import NoTickets from "components/noTickets";
import MintSection from "components/mintSection";
import MySloties from "components/mySloties";

import useWeb3 from "hooks/useWeb3";
import useProofs from "hooks/useProofs";
import usePreSaleContract from "hooks/usePreSaleContract";
import usePublicSaleContract from "hooks/usePublicSaleContract";

const MintPage = () => {
    //const navigate = useNavigate();
    const [usersTickets, setUsersTickets] = useState(undefined);
    const [usersPreTickets, setUsersPreTickets] = useState(undefined);

    const [userMintable, setUserMintable] = useState(undefined);
    const [userPreMintable, setUserPreMintable] = useState(undefined);

    const [balance, setBalance] = useState(undefined);

    const [giveAwayMinted, setGiveAwayMinted] = useState(undefined);

    const [isTx, setIsTx] = useState(undefined);

    // Mint section variables
    const [loading, setLoading] = useState(undefined);
    const loadingRef = useRef(loading);
    const isTxRef = useRef(isTx);

    const setLoadingRef = value => {
        loadingRef.current = value;
        setLoading(_ => value);
    }

    const setIsTxRef = value => {
        isTxRef.current = value;
        setIsTx(_ => value);
    }

    function contractDataLoaded() {
        let _allLoaded =usersPreTickets !== undefined &&                        
                        userPreMintable !== undefined &&
                        balance !== undefined &&
                        giveAwayMinted !== undefined
        if (isCreated) {
            _allLoaded &= usersTickets !== undefined
            _allLoaded &= userMintable !== undefined
        }
        return _allLoaded
    }

    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    useEffect(() => {
        (async function(){
            await tryAutoConnect();
            setTriedAutoConnecting(true);
        })()
    }, [])

    const [
        preSaleContract
    ] = usePreSaleContract(provider);

    const [
        publicSaleContract,
        isCreated
    ] = usePublicSaleContract(provider);

    const  [
        s_proofs,
        wl_proofs,
        g_proofs,
        w_proofs
    ] = useProofs(address);

    useEffect(() => {
        const init = async () => {
            if (isCreated) {
                let _usersTickets;
                if (w_proofs) {
                    let _usersWhaleTickets = (await publicSaleContract.phaseThreeWhaleAddressToTickets(address)).toNumber();
                    let _usersNormalTickets = (await publicSaleContract.phaseThreeAddressToTickets(address)).toNumber();
                    _usersTickets = _usersWhaleTickets + _usersNormalTickets;
                } else {
                    _usersTickets = (await publicSaleContract.phaseThreeAddressToTickets(address)).toNumber();
                }

                let _userMinted = (await publicSaleContract.addressToTicketMints(address)).toNumber();
                let _userMintable = _usersTickets - _userMinted;
    
                setUsersTickets(_usersTickets);
                setUserMintable(_userMintable);
            } else {
                setUsersTickets(0);
                setUserMintable(0);
            }
            
            let _userPreTickets =  (await preSaleContract.addressToTickets(address)).toNumber()
    
            let _userPreMinted = (await preSaleContract.addressToTicketMints(address)).toNumber();
            let _userPreMintable = _userPreTickets - _userPreMinted;
    
            let _balance = (await contractNFT.balanceOf(address)).toNumber();
    
            let _giveAwayMinted = (await preSaleContract.addressToGiveawayMints(address)).toNumber();        
    
            setUsersPreTickets(_userPreTickets);
            setUserPreMintable(_userPreMintable);
    
            setBalance(_balance);
            setGiveAwayMinted(_giveAwayMinted);

            provider.off("block");
            provider.on("block", (blockNumber) => {
                const update = async () => {
                    if (isCreated) {
                        let _usersTickets;
                        if (w_proofs) {
                            let _usersWhaleTickets = (await publicSaleContract.phaseThreeWhaleAddressToTickets(address)).toNumber();
                            let _usersNormalTickets = (await publicSaleContract.phaseThreeAddressToTickets(address)).toNumber();
                            _usersTickets = _usersWhaleTickets + _usersNormalTickets;
                        } else {
                            _usersTickets = (await publicSaleContract.phaseThreeAddressToTickets(address)).toNumber();
                        }

                        let _userMinted = (await publicSaleContract.addressToTicketMints(address)).toNumber();
                        let _userMintable = _usersTickets - _userMinted;
            
                        setUsersTickets(_ => _usersTickets);
                        setUserMintable(_ => _userMintable);
                    } else {
                        setUsersTickets(0);
                        setUserMintable(0);
                    }
                    
                    let _userPreTickets =  (await preSaleContract.addressToTickets(address)).toNumber()
            
                    let _userPreMinted = (await preSaleContract.addressToTicketMints(address)).toNumber();
                    let _userPreMintable = _userPreTickets - _userPreMinted;
            
                    let _balance = (await contractNFT.balanceOf(address)).toNumber();
            
                    let _giveAwayMinted = (await preSaleContract.addressToGiveawayMints(address)).toNumber();        
            
                    setUsersPreTickets(_ => _userPreTickets);
                    setUserPreMintable(_ => _userPreMintable);
            
                    setBalance(_ => _balance);
                    setGiveAwayMinted(_ => _giveAwayMinted);

                    if (!isTxRef.current && loadingRef.current) {
                        setLoadingRef(false);
                    }
                }                
                update();
            })
        }

        if (preSaleContract && (publicSaleContract || !isCreated) && provider && address && contractNFT && isCorrectChain) {
            init();
        }

    }, [preSaleContract, publicSaleContract, contractNFT, provider, address, isCorrectChain])

    function hasUserTickets() {
        return usersPreTickets > 0 || usersTickets > 0 || g_proofs.length > 0;
    }

    function hasMintedAll() {
        let _hasMintedAll = userPreMintable === 0;

        if (isCreated) {
            _hasMintedAll &= userMintable === 0
        }

        if (g_proofs.length > 0) {
            _hasMintedAll &= giveAwayMinted > 0
        }
        
        return _hasMintedAll;
    }

    return (
        <>
        {
            triedAutoConnecting &&
            (
                isConnected && isCorrectChain ? 
                    contractDataLoaded() ? (
                        hasUserTickets() || (balance > 0) ?
                                <>
                                    {
                                        !hasMintedAll() ?
                                            <MintSection 
                                                giveAwayMinted={giveAwayMinted}
                                                userTickets={usersTickets}
                                                userPreTickets={usersPreTickets}
                                                userMintable={userMintable}
                                                userPreMintable={userPreMintable}
                                                balance={balance}
                                                contractDataLoaded={contractDataLoaded}
                                                g_proofs={g_proofs}
                                                preSaleContract={preSaleContract}
                                                publicSaleContract={publicSaleContract}
                                                isCreated={isCreated}
                                                signer={signer}
                                                address={address}
                                                loading={loading}
                                                setLoadingRef={setLoadingRef}
                                                setIsTxRef={setIsTxRef}
                                            />
                                        :
                                            <StayUpdated
                                                text={"You don't have any tickets left to mint."}
                                            />
                                    }
                                    {
                                        balance > 0 &&
                                            <MySloties 
                                                contract={contractNFT}
                                                balance={balance}
                                                address={address}
                                            />
                                    }                                
                                </>                            
                        :
                            <NoTickets />
                    ) : <></>
                :
                isConnected && !isCorrectChain ?
                    <StayUpdated 
                        text={"You are not connected to the correct network. Please connect to the Ethereum main network."}
                    />
                :
                <Connect connect={connect} />
            )
        }
        </>
    );
};

export default MintPage;
