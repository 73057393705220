import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {ethers} from "ethers";

import Config from "config";
const getRevertReason = require('eth-revert-reason')

const ETHERSCAN_URL = Config.URLS.ETHERSCAN;
const REDEEM_URL = Config.URLS.REDEEM;
const PHASE_TWO_URL = Config.URLS.BASE + "/presale-2";
const PHASE_THREE_URL = Config.URLS.BASE + "/public";

const PhaseOneSection = ({
    address,
    s_proofs,
    contract,
    signer,
    provider
}) => {
    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        //document.dispatchEvent(new Event('readystatechange'))
      })

    const [errorMsg, SetErrorMsg] = useState(undefined);

    const [totalTickets, setTotalTickets] = useState(undefined);
    const [nftAvailable, setNFTAvailable] = useState(undefined);
    const [usersTicket, setUsersTickets] = useState(undefined);
    const [activeTickets, setActiveTickets] = useState(undefined);
    const [maxPerUser, setMaxPerUser] = useState(undefined);
    const [price, setPrice] = useState(undefined);

    const [amount, setAmount] = useState(0);
    const [txHash, setTxHash] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const [isTx, setIsTx] = useState(undefined);

    const loadingRef = useRef(loading);
    const isTxRef = useRef(isTx);

    const setLoadingRef = value => {
        loadingRef.current = value;
        setLoading(_ => value);
    }

    const setIsTxRef = value => {
        isTxRef.current = value;
        setIsTx(_ => value);
    }

    function contractDataLoaded() {
        return  totalTickets !== undefined &&
                nftAvailable !== undefined &&
                usersTicket !== undefined &&
                maxPerUser!== undefined &&
                price !== undefined;
    }

    useEffect(() => {
        const init = async () => {
            let _nftAvailable =  (await contract.maxSupply()).toNumber();
            _nftAvailable = _nftAvailable - 1;
            let _totalTickets = (await contract.ticketSales()).toNumber();
            _totalTickets = _totalTickets - 1;

            let _maxPerUser = 1;
            let _usersTicket = (await contract.addressToTickets(address)).toNumber();
            let _price = (await contract.ticketPrice());

            let _minted = (await contract.addressToTicketMints(address)).toNumber();
            let _activeTickets = _usersTicket - _minted;

            setTotalTickets(_totalTickets);
            setNFTAvailable(_nftAvailable);
            setUsersTickets(_usersTicket);
            setMaxPerUser(_maxPerUser);
            setPrice(_price);
            setActiveTickets(_activeTickets);

            provider.off("block");
            provider.on("block", (blockNumber) => {
                const update = async () => {
                    //let _nftAvailable =  (await contract.maxSupply()).toNumber();
                    //_nftAvailable = _nftAvailable - 1;
                    let _totalTickets = (await contract.ticketSales()).toNumber();
                     _totalTickets = _totalTickets - 1;
                    let _usersTicket = (await contract.addressToTickets(address)).toNumber();

                    let _minted = (await contract.addressToTicketMints(address)).toNumber();
                    let _activeTickets = _usersTicket - _minted;

                    setTotalTickets(_ => _totalTickets);
                    //setNFTAvailable(_ => _nftAvailable);
                    setUsersTickets(_ => _usersTicket);
                    setActiveTickets(_activeTickets);

                    if (!isTxRef.current && loadingRef.current) {
                        setLoadingRef(false);
                    }
                }
                update();
            })
        }

        if (contract && provider && address) {
            init();
        }
    }, [contract, provider, address])

    function isSoldOut() {
        return nftAvailable === totalTickets;
    }

    function getSmallestBuyAmountForUser() {
        return Math.max(0, maxPerUser - usersTicket); //return Math.max(0, Math.min(maxPerUser - usersTicket, nftAvailable - totalTickets));
    }

    function hasUserBoughtMaxTickets() {
        return getSmallestBuyAmountForUser() === 0;
    }

    function hasUserSomeTickets() {
        return usersTicket > 0;
    }

    async function setCorrectErrorMessage(e) {
        SetErrorMsg("An unexpected error occured and your transaction did not go through. Please check your wallet or etherscan to see the reason of the failure.");
        for (let error of Config.ERRORS.PHASE_1) {
            if (e.message.includes(error.error)) {
                let _msg = error.message;
                if (error.insert)
                    _msg = _msg.replace("[amount]", ethers.utils.formatEther(price.toString()))
                SetErrorMsg(_msg)
                return;
            }
        }
    }

     const buy = async () => {
         SetErrorMsg(undefined)

        try {
            let _amount = parseInt(amount)

            if (isNaN(_amount)) {
                SetErrorMsg("Amount must be a number.")
                return;
            }

            if (_amount <= 0) {
                SetErrorMsg("Amount of tickets must be at least one.")
                return;
            }

            if (_amount > getSmallestBuyAmountForUser()) {
                SetErrorMsg("Please enter a lower number.")
                return;
            }

            setLoadingRef(true);
            setIsTxRef(true);
            const tx = await contract.connect(signer).phaseOneBuyTicketsMerkle(
                s_proofs,
                { value: price.mul(_amount) }
            );

            setTxHash(tx.hash);
            await tx.wait(2);
        } catch (e) {
            await setCorrectErrorMessage(e);
            setLoadingRef(false)
        }
        setTxHash(undefined);
        setIsTxRef(false);
    }

    const Info = () => (
        <div className="wrapper general-info-wrapper">
            <div className="mint-card-bg-image"></div>
            <div className="spacer _2em"></div>
            <div className="grid step-three-presale-grid">
                <div>
                    <div className="text h3" ><span style={{color: "#58d658"}}>Presale 1</span></div>
                    <div className="horizontal-line"></div>
                    <div className="spacer _1em"></div>
                </div>
                <div className="wrapper flex align-baseline wrap">
                    <div className="text h5">My Address:</div>
                    <div className="spacer _0-5em"></div>
                    <div className="text address">{address}</div>
                </div>
            </div>
        </div>
    )

    const Loading = () => (
        <>
            <div id="w-node-d5084adc-18c2-8cd3-e678-d41da77696ae-b62521ab" className="text centred" style={{color: "#58d658"}}>Transaction is loading...</div>
            {
                txHash &&

                <>
                    <a id="w-node-_3eb213d0-fd57-540c-fe21-2cb07eac8d05-b62521ab" href={ETHERSCAN_URL + "/tx/" + txHash} className="link-block w-inline-block" target="_blank">
                        <div className="text bold centred">View transaction on Etherscan.</div>
                    </a>
                    <div data-w-id="531c44b4-0b49-e823-3a34-e7e8535fa824" data-animation-type="lottie" data-src="documents/lottieflow-loading-04-1-ffffff-easey.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="3.1458333333333335" data-duration="0" id="w-node-_531c44b4-0b49-e823-3a34-e7e8535fa824-b62521ab" className="lottie buy-ticket-loading-lottie"></div>
                </>
            }

        </>
    )

    const Result = () => (
        <>
            <div className="text bold">Your results:</div>
            <div className="text">
                Number of active tickets:
                <span className="text bold"> {activeTickets}
                </span>
            </div>
            <div className="text">Get more tickets at: </div>
            <div className="get-more-tickets-wrapper">
                <a href={PHASE_TWO_URL} className="button secondary small w-inline-block">
                    <div className="text">Presale 2</div>
                </a>
                <a href={PHASE_THREE_URL} className="button secondary small w-inline-block">
                    <div className="text">Public sale</div>
                </a>
            </div>
        </>
    )

    const SoldOut = () => (
        <div id="w-node-_319d11a8-8c01-b569-b7db-18a3f9e6d2ea-b62521ab" className="text bold centred" style={{color: "#d50000"}}    >Tickets are sold out!</div>
    )

    return (
        <>
        {
            contractDataLoaded() &&
            <div className="grid step-three-grid">
                <Info />
                <div className="wrapper general-info-wrapper">
                    <div className="grid step-three-presale-grid">
                        <div>
                            <div className="text h2">Buy your tickets here</div>
                            <div className="horizontal-line"></div>
                            <div className="spacer _1em"></div>
                        </div>
                        <div className="text">
                            <ul>
                                <li><strong>Phase:</strong> Presale 1</li>
                                <li><strong>Supply:</strong> {nftAvailable} </li>
                                <li><strong>Phase Open:</strong> 7 Dec 19:00 - 20:00 UTC</li>
                                <li><strong>Price:</strong> {ethers.utils.formatEther(price.toString())} ETH + Gas fees</li>
                            </ul>
                            You will be able to buy a maximum of 1 ticket in this Phase.
                            <p style={{ marginTop: 11, marginBottom: 11 }}><strong>Keep in mind:</strong></p>
                            <p style={{ marginTop: 11, marginBottom: 11 }}>🍀 2 Sloties are needed to breed a FREE Junior.</p>
                            <p style={{ marginTop: 11, marginBottom: 11 }}>👑 3 Sloties are needed to be eligible for DAO.</p>
                            <p style={{ marginTop: 11, marginBottom: 11 }}>💰 More Sloties you own, more shares from total income.</p>
                        </div>

                        {
                             !isSoldOut() &&
                             !hasUserBoughtMaxTickets() &&
                             !loading &&
                             <>
                                <div className="form-block w-form">
                                    <form id="wf-form-Buy-Ticket-Form" name="wf-form-Buy-Ticket-Form" data-name="Buy Ticket Form" className="form">
                                        <div className="text max-tickets-text">(Max. {getSmallestBuyAmountForUser()})
                                        </div>
                                        <input value={amount} onChange={e => setAmount(e.target.value)} type="number" className="text-field w-input" max={getSmallestBuyAmountForUser()} maxLength="256" name="Number-of-tickets" data-name="Number-of-tickets" min="1" placeholder="0" id="Number-of-tickets" required="" />
                                        <input onClick={buy} value="Buy tickets!" onChange={() => undefined} data-wait="Please wait..." className="button large mint-ticket-form-button w-button" />
                                    </form>
                                    <div className="success-message w-form-done">
                                    </div>
                                    <div className="error-message w-form-fail">
                                    </div>
                                </div>
                             </>
                        }
                        {
                            loading &&
                            <Loading />
                        }
                        {
                            hasUserSomeTickets() &&
                            !loading &&
                            <Result />
                        }

                        {
                            isSoldOut() &&
                            !loading &&
                            <SoldOut />
                        }
                        {
                            errorMsg &&
                            <div className="error-message text centred" style={{color: "red"}}>
                                {errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default PhaseOneSection
