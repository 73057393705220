import { useState, useEffect } from 'react';

import axios from "axios";

import { useMoralisWeb3Api } from "react-moralis";

export default function useSlotieNFTs(contract, balance, address) {
    const [tokens, setTokens] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [estimates, setEstimates] = useState([])
    const Web3Api = useMoralisWeb3Api()

    useEffect(() => {
        if (contract && address && balance > 0) {
            (async function(){
                let _tokens = [];
                let _metadatas = [];
                let _imagedatas = [];
    
                let usersSloties = await Web3Api.account.getNFTsForContract({"chain":"eth","address":address,"token_address":contract.address} );
                usersSloties = usersSloties.result

                if (usersSloties && usersSloties.length > 0) {
                    for (let i = 0; i < usersSloties.length; i++) {
                        let slotie = usersSloties[i];
                        let metadata = JSON.parse(slotie.metadata);
                        let _token = parseInt(slotie.token_id)
                        let image;
                        if (!metadata) {
                            let _tokenUri = slotie.token_uri;
                            if (!_tokenUri) {
                                _tokenUri = await contract.tokenURI(_token);
                            }
                            metadata = await axios.get(_tokenUri);
                            metadata = metadata.data
                            image = metadata.image
                        } else {
                            image = metadata.image
                        }
                        let _tokenUri = await contract.tokenURI(_token);                           
                        metadata = await axios.get(_tokenUri);
                        metadata = metadata.data
                        image =  metadata.image ? metadata.image.replace("gateway.pinata.cloud", "sloties.mypinata.cloud") :  metadata.image
                        
                        _metadatas.push(metadata)
                        _imagedatas.push(image)
                        _tokens.push(_token)
                    }

                    setTokens(_tokens);
                    setMetadata(_metadatas);
                    setImageData(_imagedatas)
                }                                  
                                
            })()
        }
    }, [contract, balance, address])

    return [
        tokens,
        metadata,
        imageData
    ]
}