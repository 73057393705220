import { useState, useEffect } from 'react';
import SProofs from "proofs/s_proofs.json"
import WLProofs from "proofs/wl_proofs.json"
import GProofs from "proofs/g_proofs.json"
import WProofs from "proofs/w_proofs.json"

export default function useProofs(address) {
    const [s_proofs, set_s_proofs] = useState(undefined);
    const [wl_proofs, set_wl_proofs] = useState(undefined);
    const [g_proofs, set_g_proofs] = useState([]);
    const [w_proofs, set_w_proofs] = useState(undefined);

    useEffect(() => {  
        if (address) {
            let _s = SProofs.proofs[address.toLowerCase()];
            let _wl = WLProofs.proofs[address.toLowerCase()];
            let _g = GProofs.proofs[address.toLowerCase()];
            let _w = WProofs.proofs[address.toLowerCase()];

            if (_s)
                set_s_proofs(_s);
            
            if (_g)
                set_g_proofs(_g)

            if (_wl)
                set_wl_proofs(_wl)

            if (_w)
                set_w_proofs(_w);
        }
    }, [address])

    return [
        s_proofs,
        wl_proofs,
        g_proofs,
        w_proofs
    ]
}