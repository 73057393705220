/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {ethers} from "ethers";
import axios from "axios";

import Connect from "components/connect";
import PhaseThreeSection from "components/phaseThreeSection/";
import StayUpdated from "components/stayUpdated";

import useWeb3 from "hooks/useWeb3";
import usePublicSaleContract from "hooks/usePublicSaleContract";
import useProofs from "hooks/useProofs";

import Config from "config";

const BASE_URL = Config.URLS.BASE;
const ETHERSCAN_URL = Config.URLS.ETHERSCAN;

const PhaseThree= () => {
    const [isActive, setIsActive] = useState(undefined);
    const [isFinished, setIsFinished] = useState(undefined);

    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    useEffect(() => {
        (async function(){
            await tryAutoConnect();
            setTriedAutoConnecting(true);
        })()
    }, [])

    const [
        publicSaleContract,
        isCreated
    ] = usePublicSaleContract(provider);

    useEffect(() => {
        if (publicSaleContract && isCorrectChain) {
            (async function (){
                try {
                    let _active = await publicSaleContract.phaseThreeActive();
                    let _ticketsSold = (await publicSaleContract.phaseThreeTicketSales()).toNumber();

                    setIsActive(_active)
                    setIsFinished(_ticketsSold > 1 && !_active)
                } catch (e) {
                    console.log(e)
                }

            })();
        }
    }, [publicSaleContract, isCorrectChain]);

    const  [
        s_proofs,
        wl_proofs,
        g_proofs,
        w_proofs
    ] = useProofs(address);

    return (
        <>
        {
            triedAutoConnecting &&
            (
                isConnected && isCorrectChain ?
                    !isCreated ?
                        <StayUpdated
                            text={"Public sale is not active yet."}
                        />
                    :
                    isActive !== undefined && (
                        isActive ?
                            <PhaseThreeSection
                                address={address}
                                contract={publicSaleContract}
                                signer={signer}
                                provider={provider}
                                w_proofs={w_proofs}
                            />
                            :
                            isFinished ?
                            <StayUpdated
                                text={"Public sale is finished."}
                            />
                            :
                            <StayUpdated
                                text={"Public sale is not active yet."}
                            />
                    )
                :
                isConnected && !isCorrectChain ?
                    <StayUpdated
                        text={"You are not connected to the correct network. Please connect to the Ethereum main network."}
                    />
                :
                <Connect connect={connect} />
            )
        }
        </>
    );
};

export default PhaseThree;
