import { useState, useEffect } from 'react';
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ethers} from "ethers";
import ContractPreSaleABI from "../abis/SlotiePreSale.json";

export default function usePreSaleContract(provider) {
    const [preSaleContract, setPreSaleContract] = useState(null);

    useEffect(() => {
        if (provider) {
            const _contract = new ethers.Contract(ContractPreSaleABI.address, ContractPreSaleABI.abi, provider)
            setPreSaleContract(_contract);
            (async function() {
                // Dev commands
            //let _localProvider = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545/");
            //let _localSigner = await _localProvider.getSigner();

            //await _contract.connect(_localSigner).makePhaseOneActive(false);
            //await _contract.connect(_localSigner).makePhaseTwoActive(true);
            })()            
        }
    }, [provider])

    return [
        preSaleContract
    ]
}