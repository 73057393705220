import { useState, useEffect } from 'react';
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ethers} from "ethers";
import ContractPublicSaleABI from "../abis/SlotiePublicSale.json";

export default function usePreSaleContract(provider) {
    const [publicSaleContract, setPublicSaleContract] = useState(null);
    const [isCreated, setIsCreated] = useState(null);

    useEffect(() => {
        if (provider && ContractPublicSaleABI.address && ContractPublicSaleABI.abi) {
            const _contract = new ethers.Contract(ContractPublicSaleABI.address, ContractPublicSaleABI.abi, provider)
            setPublicSaleContract(_contract);
            setIsCreated(true);
            (async function() {
                // Dev commands
                /*let _localProvider = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545/");
                let _localSigner = await _localProvider.getSigner();

                await _contract.connect(_localSigner).makePhaseThreeActive(true);*/
            })()            

        } else {
            setIsCreated(false);
        }
    }, [provider])

    return [
        publicSaleContract,
        isCreated
    ]
}