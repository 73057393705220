import React, { useState, useEffect } from "react";

import useSlotieNFTs from "hooks/useSlotieNFTs";
import Estimates from "assets/estimates.json";

import Config from "config";

const OS_URL = Config.URLS.OPENSEA;

const MySloties = ({
    contract,
    balance,
    address
}) => {

    const [
        tokens,
        metadata,
        imageData
    ] = useSlotieNFTs(contract, balance, address);

    const [slotiesArray, setSlotiesArray] = useState([]);

    useEffect(() => {
        if (metadata.length === tokens.length && imageData.length === tokens.length) {
            let _arr = tokens.map((t, i) => {
                return <SlotieSection
                            name={metadata[i]["name"]}
                            no={t}
                            //description={metadata[i]["description"]}
                            estimate={Estimates.length > 0 ? Estimates[t] : undefined}
                            img={imageData[i]}
                            key={i}
                        />
            })
            setSlotiesArray(_arr)
        }
    }, [tokens, metadata, imageData])

    const SlotieSection = ({ name, no, description, img, estimate }) => (
        <div className="grid my-sloties-child-grid">
            <img src={img} loading="eager" alt={name} className="image my-slotie-image" />
            <div>
                <div className="text my-slotie-heading">{name}</div>
                <div className="text small">{description}</div>
                {
                    estimate &&
                    <div className="text small">Estimated value: ${estimate.toString()}</div>
                }
                <a id="w-node-_3eb213d0-fd57-540c-fe21-2cb07eac8d05-b62521ab" href={OS_URL + "/assets/" + contract.address + "/" + no} target="_blank" className="link-block w-inline-block">
                    <div className="text small">View on Opensea</div>
                </a>
            </div>
        </div>
    )

    return (
        <div className="wrapper my-sloties-wrapper">
            <div className="grid redeem-tickets-grid">
            <div>
                <div className="text h2">My Sloties</div>
                <div className="horizontal-line"></div>
                <div className="spacer _1em"></div>
            </div>

            <div className="grid my-sloties-grid">
                    {
                        slotiesArray.length > 0 ?
                            slotiesArray
                        :
                        <>
                            <div></div>
                            <div id="w-node-_3f5172fc-3a99-4198-b0a6-daa31cbe7241-b62521ab" className="text centred"><p>Your NFTs are loading...</p></div>
                            <div data-w-id="801ce689-37a9-6e14-c8b5-ed4298891929" data-animation-type="lottie" data-src="documents/lottieflow-loading-04-1-ffffff-easey.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="3.1458333333333335" data-duration="0" id="w-node-_801ce689-37a9-6e14-c8b5-ed4298891929-b62521ab" className="lottie buy-ticket-loading-lottie"></div>
                        </>
                    }
            </div>
            </div>
        </div>
    )
}

export default MySloties
