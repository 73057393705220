const Config = {
    NETWORK: {
        CHAIN_ID: 1
    },
    URLS: {
        BASE: "https://mint.slotie.com/#",
        ETHERSCAN: "https://etherscan.io",
        OPENSEA: "https://opensea.io",
        REDEEM: "https://mint.slotie.com/#/mint"
    },
    ERRORS: {
        PHASE_1: [
            {
                error: "PHASE ONE NOT ACTIVE",
                message: "Error: Phase one of the pre-sale is not active."
            },
            {
                error: "ALREADY BOUGHT MAX TICKET",
                message: "Error: Your transaction has not gone through. You already bought the maximum amount of tickets per user."
            },
            {
                error: "INCORRECT AMOUNT PAID",
                message: "Error: Your transaction has not gone through. An incorrect amount of Ethereum was paid for the bought tickets"
            },
            {
                error: "PHASE 1 TICKETS SOLD",
                message: "Error: Your transaction has not gone through. The amount of tickets you bought are not available anymore."
            },
            {
                error: "INCORRECT NONCE",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "SIGNATURE NOT FROM PROVIDER WALLET",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "HAVE TO BUY AT LEAST 1",
                message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
            },
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to complete the transaction. The price for one ticket is [amount] Ether.",
                insert: true
            },
            {
                error: "INVALID PROOF",
                message: "Error: You are not on the white list. Please contact the team if you think this is incorrect."
            }
        ],
        PHASE_2: [
            {
                error: "PHASE TWO NOT ACTIVE",
                message: "Error: Phase two of the pre-sale is not active."
            },
            {
                error: "ALREADY BOUGHT MAX TICKET",
                message: "Error: Your transaction has not gone through. You already bought the maximum amount of tickets per user."
            },
            {
                error: "INCORRECT AMOUNT PAID",
                message: "Error: Your transaction has not gone through. An incorrect amount of Ethereum was paid for the bought tickets"
            },
            {
                error: "PHASE 2 TICKETS SOLD",
                message: "Error: Your transaction has not gone through. The amount of tickets you bought are not available anymore."
            },
            {
                error: "INCORRECT NONCE",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "SIGNATURE NOT FROM PROVIDER WALLET",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "HAVE TO BUY AT LEAST 1",
                message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
            },
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to complete the transaction. The price for one ticket is [amount] Ether.",
                insert: true
            },
            {
                error: "INVALID PROOF",
                message: "Error: You are not on the white list. Please contact the team if you think this is incorrect."
            }
        ],
        PHASE_3: [
            {
                error: "PHASE THREE NOT ACTIVE",
                message: "Error: public sale is not active."
            },
            {
                error: "INCORRECT TICKET AMOUNT",
                message: "Error: Your transaction has not gone through. You already bought the maximum amount of tickets per user."
            },
            {
                error: "INCORRECT AMOUNT PAID",
                message: "Error: Your transaction has not gone through. An incorrect amount of Ethereum was paid for the bought tickets"
            },
            {
                error: "PHASE 3 TICKETS SOLD",
                message: "Error: Your transaction has not gone through. The amount of tickets you bought are not available anymore."
            },
            {
                error: "INCORRECT NONCE",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "SIGNATURE NOT FROM PROVIDER WALLET",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "HAVE TO BUY AT LEAST 1",
                message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
            },
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to complete the transaction. The price for one ticket is [amount] Ether.",
                insert: true
            }
        ],
        MINT: [
            {
                error: "NO MINTABLE TICKETS",
                message: "Error: Your transaction has not gone through. You don't have any tickets which are eligible to mint."
            },
            {
                error: "INCORRECT NONCE",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "SIGNATURE NOT FROM PROVIDER WALLET",
                message: "Error: Your transaction has not gone through. Something went wrong with verifying your address. Please contact support immediatly."
            },
            {
                error: "ALREADY CLAIMED",
                message: "Error: Your transaction has not gone through. You have already minted your giveaway sloties."
            },
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to complete the transaction. Make sure you have enough Ether to pay gas.",
                insert: true
            },
            {
                error: "INVALID PROOF",
                message: "Error: You are not on the giveaway list. Please contact the team if you think this is incorrect."
            }
        ]
    }
}

export default Config