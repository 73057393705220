import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import App from "./app"
import { MoralisProvider } from "react-moralis";

ReactDOM.render(
    <MoralisProvider appId="m2nedYw0TdagIidoBMgvDXIEYLRsSVXvlOdCdAb9" serverUrl="https://unnzl7pswozx.usemoralis.com:2053/server">
        <App />
    </MoralisProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
