import React, { useState, useEffect } from "react";

import Config from "config"

const PHASE_ONE_URL = Config.URLS.BASE + "/presale-1";
const PHASE_TWO_URL = Config.URLS.BASE + "/presale-2";
const PHASE_THREE_URL = Config.URLS.BASE + "/public";
const MINT_URL = Config.URLS.BASE + "/mint";

const Header = () => {
    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        document.dispatchEvent(new Event('readystatechange'))
    })

    return (
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
            <div className="spacer _0-5em"></div>
            <div className="container navbar-container">
                <div className="wrapper nav-wrapper">
                    <a href="https://www.slotie.com" className="link-block nav-logo w-inline-block"><img src="images/logo_white.svg" loading="lazy" width="75" alt="" className="logo slotie-nav-logo"/>
                    <div className="text h3 no-margin">Slotie</div>
                    </a>
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <div className="wrapper nav-button-wrapper">
                            <a href={PHASE_ONE_URL} className="button secondary small w-inline-block">
                            <div className="text">Presale 1</div>
                            </a>
                        </div>
                        <div className="wrapper nav-button-wrapper">
                            <a href={PHASE_TWO_URL} className="button secondary small w-inline-block">
                            <div className="text">Presale 2</div>
                            </a>
                        </div>
                        <div className="wrapper nav-button-wrapper">
                            <a href={PHASE_THREE_URL} className="button secondary small w-inline-block">
                            <div className="text">Public Sale</div>
                            </a>
                        </div>
                        <div className="wrapper nav-social-wrapper">
                            <a href="https://twitter.com/SlotieNft" target="_blank" className="link-block w-inline-block"><img src="images/Logo---Twitter.svg" loading="lazy" alt="Twitter logo." className="icon twitter-icon nav-twitter-icon"/></a>
                            <div className="spacer _1em tablet-2em"></div>
                            <a href="https://discord.gg/d4hCRCTcAk" target="_blank" className="link-block w-inline-block"><img src="images/Logo---Discord.svg" loading="lazy" alt="Discord logo." className="icon discord-icon nav-discord-icon"/></a>
                        </div>
                    </nav>
                    <div className="menu-button w-nav-button">
                    <div className="w-icon-nav-menu"></div>
                    </div>
                </div>
                <div className="spacer _0-5em"></div>
                </div>
            <div className="horizontal-line"></div>
        </div>
    )
}

export default Header
