import { useState } from 'react';
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ethers} from "ethers";
import ContractNFTABI from "../abis/Slotie.json";

import Config from "config"

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: "ca2fd8b0adf9417e8090f414d57e0703",
        }
    }
};

const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
    disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export default function useWeb3() {
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [address, setAddress] = useState(null);
    const [contractNFT, setContractNFT] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isCorrectChain, setIsCorrectChain] = useState(undefined)
    const [triedAutoConnecting, setTriedAutoConnecting] = useState(null);

    async function initiateWeb3(proxy) {
        const _provider = new ethers.providers.Web3Provider(proxy);

        const _signer = await _provider.getSigner();
        const _address = await _signer.getAddress();
        const _contractNFT = new ethers.Contract(ContractNFTABI.address, ContractNFTABI.abi, _provider)
        const { chainId } = await _provider.getNetwork();

        // Dev commands
        /*let _localProvider = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545/");
        let _localSigner = await _localProvider.getSigner();

        await _localSigner.sendTransaction({ 
            to: "0x9Cc3d5291D1dc1C2E3c49f002CC31eeDBB687c0f",
            value: ethers.utils.parseEther("600.0") });

        await _localSigner.sendTransaction({ 
            to: "0xD5c502B0BB62A9F3EBf5032A9C764919E2321D21",
            value: ethers.utils.parseEther("600.0") });

        await _localSigner.sendTransaction({ 
            to: "0x8301b459d30902192e0a4e9929B10A38c35269cC",
            value: ethers.utils.parseEther("600.0") });
            
        await _localSigner.sendTransaction({ 
            to: "0x6C202bd88BFa40a13C43B61E9128B0912F2421dD",
            value: ethers.utils.parseEther("600.0") });
            
        await _localSigner.sendTransaction({ 
            to: "0xAd693404918c5e9C3D3CB0Aa26e18CC2E64b9b4F",
            value: ethers.utils.parseEther("600.0") });*/


        setProvider(_provider);
        setSigner(_signer);
        setAddress(_address);
        setContractNFT(_contractNFT);
        setIsConnected(true)
        setIsCorrectChain(chainId === Config.NETWORK.CHAIN_ID);
    }

    async function tryAutoConnect() {
        try {
            let { cachedProvider } = web3Modal;
            let cachedFromStorage = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));
    
            if(cachedProvider && cachedProvider !== "") {
                await initiateWeb3(await web3Modal.connectTo(cachedProvider))
                return true
            } else if (cachedFromStorage && cachedFromStorage != "") {
                await initiateWeb3(await web3Modal.connectTo(cachedFromStorage))
                return true
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    async function connect() {
        try {
           // await web3Modal.clearCachedProvider();
            await initiateWeb3(await web3Modal.connect())
        } catch (e) {
            alert(e.message)
        }
    }

    return [
        provider,
        signer,
        address,
        contractNFT,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ]
}