/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {ethers} from "ethers";
import axios from "axios";

import Connect from "components/connect";
import PhaseTwoSection from "components/phaseTwoSection";
import StayUpdated from "components/stayUpdated";

import useWeb3 from "hooks/useWeb3";
import usePreSaleContract from "hooks/usePreSaleContract";
import useProofs from "hooks/useProofs";

import Config from "config";
const PUBLIC_SALE_URL = Config.URLS.BASE + "/public";

const PhaseTwo = () => {
    const [isActive, setIsActive] = useState(undefined);
    //const [isFinished, setIsFinished] = useState(undefined);
    
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    useEffect(() => {
        (async function(){
            await tryAutoConnect();
            setTriedAutoConnecting(true);
        })()
    }, [])

    const [
        preSaleContract
    ] = usePreSaleContract(provider);

    const  [
        s_proofs,
        wl_proofs,
        g_proofs,
        w_proofs
    ] = useProofs(address);

    useEffect(() => {
        if (preSaleContract && isCorrectChain) {
            (async function (){
                let _active = await preSaleContract.phaseTwoActive();
                //let _ticketsSold = (await preSaleContract.phaseTwoTicketSales()).toNumber();

                setIsActive(_active)                
                //setIsFinished(_ticketsSold > 0 && !_active)
            })();  
        }              
    }, [preSaleContract, isCorrectChain]);

    function isWhitelisted() {
        return wl_proofs !== undefined;
    }

    return (
        <>
        {
            triedAutoConnecting &&
                (
                    isConnected && isCorrectChain ? 
                        isActive !== undefined && 
                        (isWhitelisted() ?
                            isActive ?
                                <PhaseTwoSection 
                                    address={address}
                                    wl_proofs={wl_proofs}
                                    contract={preSaleContract}
                                    signer={signer}
                                    provider={provider}
                                />
                               // :
                               // isFinished ?
                               // <StayUpdated 
                               //     text={"Pre-sale two is finished."}
                               // />
                                :                     
                                <StayUpdated 
                                    text={"Pre-sale two is not active yet."}
                                />
                        :
                        <StayUpdated 
                            text={"You are not whitelisted! Please visit the public sale page for more info."}
                            link={PUBLIC_SALE_URL}
                            linkText={"Public Sale"}
                        />)
                    :
                    isConnected && !isCorrectChain ?
                        <StayUpdated 
                            text={"You are not connected to the correct network. Please connect to the Ethereum main network."}
                        />
                    :
                    <Connect connect={connect} />
                )
        }
        </>
    );
};

export default PhaseTwo;

